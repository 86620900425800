exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admisiones-index-js": () => import("./../../../src/pages/admisiones/index.js" /* webpackChunkName: "component---src-pages-admisiones-index-js" */),
  "component---src-pages-biblioteca-index-js": () => import("./../../../src/pages/biblioteca/index.js" /* webpackChunkName: "component---src-pages-biblioteca-index-js" */),
  "component---src-pages-carrera-wp-carrera-nombre-js": () => import("./../../../src/pages/carrera/{WpCarrera.nombre}.js" /* webpackChunkName: "component---src-pages-carrera-wp-carrera-nombre-js" */),
  "component---src-pages-centro-de-educacion-tecnica-huascar-rodriguez-herrera-js": () => import("./../../../src/pages/centro-de-educacion-tecnica-huascar-rodriguez-herrera.js" /* webpackChunkName: "component---src-pages-centro-de-educacion-tecnica-huascar-rodriguez-herrera-js" */),
  "component---src-pages-centro-mipymes-index-js": () => import("./../../../src/pages/centro-mipymes/index.js" /* webpackChunkName: "component---src-pages-centro-mipymes-index-js" */),
  "component---src-pages-decanato-estudiantes-index-js": () => import("./../../../src/pages/decanato-estudiantes/index.js" /* webpackChunkName: "component---src-pages-decanato-estudiantes-index-js" */),
  "component---src-pages-departamento-wp-departamento-nombre-js": () => import("./../../../src/pages/departamento/{WpDepartamento.nombre}.js" /* webpackChunkName: "component---src-pages-departamento-wp-departamento-nombre-js" */),
  "component---src-pages-direccion-extension-y-proyectos-index-js": () => import("./../../../src/pages/direccion-extension-y-proyectos/index.js" /* webpackChunkName: "component---src-pages-direccion-extension-y-proyectos-index-js" */),
  "component---src-pages-egresados-index-js": () => import("./../../../src/pages/egresados/index.js" /* webpackChunkName: "component---src-pages-egresados-index-js" */),
  "component---src-pages-facultad-wp-facultad-nombre-js": () => import("./../../../src/pages/facultad/{WpFacultad.nombre}.js" /* webpackChunkName: "component---src-pages-facultad-wp-facultad-nombre-js" */),
  "component---src-pages-grado-wp-grado-nombre-js": () => import("./../../../src/pages/grado/{WpGrado.nombre}.js" /* webpackChunkName: "component---src-pages-grado-wp-grado-nombre-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investigacion-carrera-nacional-de-investigadores-js": () => import("./../../../src/pages/investigacion/carrera-nacional-de-investigadores.js" /* webpackChunkName: "component---src-pages-investigacion-carrera-nacional-de-investigadores-js" */),
  "component---src-pages-investigacion-facilidades-js": () => import("./../../../src/pages/investigacion/facilidades.js" /* webpackChunkName: "component---src-pages-investigacion-facilidades-js" */),
  "component---src-pages-investigacion-index-js": () => import("./../../../src/pages/investigacion/index.js" /* webpackChunkName: "component---src-pages-investigacion-index-js" */),
  "component---src-pages-liaai-js": () => import("./../../../src/pages/liaai.js" /* webpackChunkName: "component---src-pages-liaai-js" */),
  "component---src-pages-lineadeinvestigacion-wp-linea-de-investigacion-nombre-js": () => import("./../../../src/pages/lineadeinvestigacion/{WpLineaDeInvestigacion.nombre}.js" /* webpackChunkName: "component---src-pages-lineadeinvestigacion-wp-linea-de-investigacion-nombre-js" */),
  "component---src-pages-lineas-de-investigacion-index-js": () => import("./../../../src/pages/lineas-de-investigacion/index.js" /* webpackChunkName: "component---src-pages-lineas-de-investigacion-index-js" */),
  "component---src-pages-nosotros-index-js": () => import("./../../../src/pages/nosotros/index.js" /* webpackChunkName: "component---src-pages-nosotros-index-js" */),
  "component---src-pages-oferta-academica-index-js": () => import("./../../../src/pages/oferta-academica/index.js" /* webpackChunkName: "component---src-pages-oferta-academica-index-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-wp-post-slug-js": () => import("./../../../src/pages/{WpPost.slug}.js" /* webpackChunkName: "component---src-pages-wp-post-slug-js" */),
  "component---src-templates-category-archive-js": () => import("./../../../src/templates/category-archive.js" /* webpackChunkName: "component---src-templates-category-archive-js" */)
}

